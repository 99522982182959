<template>
  <span>
    <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-user">
      <h1 class="title-topo">Lista de Usuários</h1>

      <!-- Campo de Busca -->
      <v-text-field
        class="default-search"
        v-model="search"
        label="Busca"
        single-line
        hide-details
      ></v-text-field>

      <!-- Campo de Filtro -->
      <v-select
        v-model="filterStatus"
        :items="filterOptions"
        item-text="text"
        item-value="value"
        label="Filtrar Usuários"
        class="mb-3"
      ></v-select>

      <!-- Tabela de Usuários -->
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="filteredUsers"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.admin="{ item }">
          <v-chip :color="item.admin ? 'green' : 'red'" dark small>
            {{ item.admin ? "Administrador" : "Usuário" }}
          </v-chip>
        </template>
        <!-- Exibe o Status Ativo -->
        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            color="green darken-2"
            @change="toggleActive(item)"
          ></v-switch>
        </template>

        <!-- Ações (Editar Usuário) -->
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </admin-blade>
  </span>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import { mapActions, mapState } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";

export default {
  components: {
    AdminBlade,
  },
  data() {
    return {
      search: "",
      filterStatus: "todos", // Valor padrão do filtro
      filterOptions: [
        { value: "todos", text: "Todos os Usuários" },
        { value: "ativos", text: "Usuários Ativos" },
        { value: "inativos", text: "Usuários Inativos" },
      ],
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "NOME", value: "name", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        { text: "TIPO DE ACESSO", value: "admin", class: "black--text" },
        { text: "Cliente", value: "fantasy_name", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  computed: {
    ...mapState("User", ["list"]), // Supondo que "list" contém os usuários
    filteredUsers() {
      // Filtra os usuários com base no filtro e na busca
      let filtered = this.list;
      if (this.filterStatus === "ativos") {
        filtered = filtered.filter((user) => user.active);
      } else if (this.filterStatus === "inativos") {
        filtered = filtered.filter((user) => !user.active);
      }
      return filtered;
    },
  },
  methods: {
    ...mapActions("User", ["setList"]),
    edit(itemId) {
      this.$router.push(`form-user/${itemId}`);
    },
    getAcessType(value) {
      return value ? "admin-table-class" : "store-table-class";
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>

<style scoped>
.admin-table-class {
  background: green;
  border-radius: 10px;
  padding: 5px;
  color: white;
}
.store-table-class {
  background: blue;
  border-radius: 10px;
  padding: 5px;
  color: white;
}
.v-chip {
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  width: auto;
  
}
</style>
