<template>
  <span>
    <v-form ref="form" v-model="valid">
      <v-row style="padding:10px; background:#EFEBE9; border-radius:0px">
        <v-col cols="6" sm="1">
          <v-text-field
            :disabled="checkIfValue(id)"
            :rules="rulesFunction('Quantidade', 1)"
            type="number"
            label="Qtd *"
            v-model="formData.balance"
          ></v-text-field>
          <strong class="red--text">{{ errors.balance }}</strong>
        </v-col>
        <v-col cols="6" sm="2">
          <v-text-field
            hide-details
            :disabled="editForm()"
            v-model="formData.color"
            v-if="writeColorName"
            label="Nome Cor *"
          >
          </v-text-field>
          <v-autocomplete
            hide-details
            style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
            "
            v-else
            @input="setWhiteColor()"
            :disabled="editForm()"
            v-model="formData.color"
            :items="colors"
            item-text="name"
            item-value="name"
            label="Nome Cor *"
            color="amber darken-4"
            :rules="rulesFunction(' Cor', 1)"
          ></v-autocomplete>
          <a
            v-if="!writeColorName"
            @click="setWriteColorName(true)"
            style="
              font-size: 12px;
              padding-top: -20px !important;
              margin-top: -20px !important;
            "
          >
            Digitar nome cor
          </a>
          <a
            v-else
            @click="setWriteColorName(false)"
            style="
              font-size: 12px;
              padding: 0px !important;
              margin: 0px !important;
            "
          >
            Voltar Selecao Cores
          </a>
          <!-- <v-btn style="size: 10px !important" text>Digitar nome cor</v-btn> -->
          <strong class="red--text">{{ errors.colors }}</strong>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            :disabled="editForm()"
            hide-details
            v-model="formData.size"
            v-if="writeSizeName"
            label="Nome Tamanho *"
          >
          </v-text-field>
          <v-autocomplete
            v-else
            hide-details
            style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
            "
            :disabled="editForm()"
            v-model="formData.size"
            :items="sizes"
            item-text="name"
            item-value="name"
            label="Tamanho *"
            color="amber darken-4"
          ></v-autocomplete>
          <a
            :disabled="editForm()"
            v-if="!writeSizeName"
            @click="setWriteSizeName(true)"
            style="
              font-size: 12px;
              padding: 0px !important;
              margin-top: 0px !important;
            "
          >
            Digitar nome tamanho
          </a>

          <a
            v-else
            :disabled="editForm()"
            @click="setWriteSizeName(false)"
            style="
              font-size: 12px;
              padding: 0px !important;
              margin: 0px !important;
            "
          >
            Voltar Selecao Tamanho
          </a>
        </v-col>
        <v-col cols="2" v-if="!id">
          <v-autocomplete
            v-model="formData.reason"
            :items="reasonIn"
            item-text="name"
            item-value="value"
            label="Tipo Entrada *"
            color="amber darken-4"
            :rules="rulesFunction('Entrada', 1)"
          ></v-autocomplete>
          <strong class="red--text">{{ errors.reason }}</strong>
        </v-col>
        <v-col cols="6" sm="2">
          <v-text-field
            :disabled="editForm()"
            label="Codigo"
            v-model="formData.code"
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="2">
          <v-text-field
            :disabled="editForm()"
            label="Id Integrador"
            v-model="formData.id_grid_integrator"
          ></v-text-field>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="3">
          <v-btn
            v-if="!updateEnable && formData.id"
            @click="updateEnable = true"
            color="orange"
            >Alterar Informações</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="!useImg">
        <v-col cols="12">
          <div>
            <v-switch
              class="no-padding"
              :disabled="editForm()"
              color="amber darken-4"
              v-model="secondColor"
              label="Cor Secundaria"
            ></v-switch>
          </div>
        </v-col>
        <v-col cols="12" sm="4" v-if="formData.color !== 'N/A'">
          <div>Cor:</div>
          <v-color-picker
            :disabled="editForm()"
            mode="hexa"
            :rules="rulesFunction('Cor', 1)"
            v-model="formData.code_color"
          ></v-color-picker>
          <strong class="red--text">{{ errors.code_color }}</strong>
        </v-col>
        <v-col cols="12" sm="4" v-if="formData.color !== 'N/A' && secondColor">
          <div>Cor Secundaria:</div>
          <v-color-picker
            :disabled="editForm()"
            mode="hexa"
            :rules="rulesFunction('Cor', 1)"
            v-model="formData.second_code_color"
          ></v-color-picker>
          <strong class="red--text">{{ errors.second_code_color }}</strong>
        </v-col>
        <v-col cols="12"> </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-img
            v-if="useImg && base64"
            contain
            :src="base64"
            height="250px"
            width="65px"
          ></v-img>
          <strong>
            <label v-if="useImg" for="imgpicker">Selecione uma Imagem</label>
          </strong>
          <image-uploader
            id="imgpicker"
            v-if="useImg"
            class="mb-6 mt-4"
            :maxWidth="800"
            :maxHeight="1000"
            :quality="1.0"
            outputFormat="verbose"
            @input="setImage"
            :preview="false"
            :capture="false"
            accept="video/*, image/*"
          ></image-uploader>
          <v-switch
            :disabled="editForm()"
            class="no-padding"
            color="amber darken-4"
            v-model="useImg"
            label="Utilizar Imagem de Fundo"
          ></v-switch>
        </v-col>
      </v-row>

      <div v-if="id && editForm()">
        <stock-change :gridId="id"></stock-change>
      </div>
      <v-row>
        <v-col sm="2" cols="12">
          <v-switch
            :disabled="editForm()"
            color="amber darken-4"
            v-if="formData.id"
            v-model="formData.active"
            label="Ativo"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12">
          <p v-if="formData.updated_at">
            <strong>Ultima Modificação:</strong>
            {{ formData.updated_at }}
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <p v-if="formData.created_at">
            <strong>Data Cadastro:</strong>
            {{ formData.created_at }}
          </p>
        </v-col>
      </v-row>
      <v-btn
        v-if="!id"
        :disabled="!valid"
        class="mr-4 register-btn"
        @click="saveContent()"
        >Salvar</v-btn
      >
      <v-btn
        v-if="id && !editForm()"
        class="mr-4 register-btn"
        @click="updateContent()"
        >Salvar Alteração</v-btn
      >
      <v-btn
      color="red darken-2 white--text"
      class="mr-4"
      :class="{'mt-4': $vuetify.breakpoint.xsOnly, 'mt-0': $vuetify.breakpoint.smAndUp}"
      @click="setModal(false)"
    >
      Cancelar
    </v-btn>
    
    </v-form>
  </span>
</template>

<script>
import stockChange from "@/views/ProductSession/Pages/Product/Grid/Components/stockChange";
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  watch: {
    formData() {
      this.base64 = this.formData.bg_color_img;
      if (this.base64) {
        this.useImg = true;
      }
      if (this.formData.second_code_color) {
        this.secondColor = true;
      }
    },
  },
  props: {
    id: Number,
  },
  components: {
    stockChange,
  },
  data: function () {
    return {
      base64: null,
      valid: false,
      codeColor: null,
      updateEnable: false,
      writeColorName: false,
      secondColor: false,
      useImg: false,
      writeSizeName: false,
    };
  },
  computed: {
    ...mapState("Grid", ["userType", "errors", "size", "formData", "reasonIn"]),
    ...mapState("Utils", ["colors", "sizes"]),
  },
  methods: {
    ...mapActions("Grid", [
      "setFormData",
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
      "setModal",
    ]),
    setImage: function (output) {
      this.base64 = output.dataUrl;
    },
    setWriteSizeName(value) {
      this.writeSizeName = value;
    },
    setWriteColorName(value) {
      this.writeColorName = value;
    },
    setWhiteColor() {
      if (this.formData.code_color == null) {
        this.formData.code_color = "#FFFFFF";
      }
    },
    checkIfValue(value) {
      if (value) {
        return true;
      } else {
        return false;
      }
    },
    editForm() {
      if (!this.updateEnable && this.id) {
        return true;
      } else {
        return false;
      }
    },
    updateContent() {
      console.log(this.base64);
      let data = {
        bg_color_img: this.base64,
        ...this.formData,
        product_id: this.$route.params.id,
      };
      if (this.formData.code_color && this.formData.code_color.hex) {
        data.code_color = this.formData.code_color.hex;
      } else {
        data.code_color = this.formData.code_color;
      }
      if (this.secondColor) {
        if (this.formData.second_code_color.hex) {
          data.second_code_color = this.formData.second_code_color.hex;
        } else {
          data.second_code_color = this.formData.second_code_color;
        }
      } else {
        data.second_code_color = null;
      }
      if (this.formData.color == "N/A") {
        data.code_color = "N/A";
      }

      if (this.useImg) {
        (data.code_color = null),
          (data.second_code_color = null),
          (data.bg_color_img = this.base64);
      } else {
        data.bg_color_img = null;
      }
      this.update(data);
    },
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    saveContent() {
      let data = {
        ...this.formData,
        bg_color_img: this.base64,
        product_id: this.$route.params.id,
      };
      if (this.formData.code_color.hex) {
        data.code_color = this.formData.code_color.hex;
      } else {
        data.code_color = this.formData.code_color;
      }
      if (this.secondColor) {
        if (this.formData.second_code_color.hex) {
          data.second_code_color = this.formData.second_code_color.hex;
        } else {
          data.second_code_color = this.formData.second_code_color;
        }
      } else {
        data.second_code_color = null;
      }
      if (this.formData.color == "N/A") {
        data.code_color = "N/A";
      }
      // console.log(data);
      this.save(data);
    },
  },
  mounted() {
    this.setFormData({ reason: "b" });
    if (this.id) {
      this.show(this.id);
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>