<template>
  <v-card class="pagarme-pgto-card">
    <h3>Chave Pagarme</h3>
    <p v-if="!tokenId" class="red--text">
      *Cadastre seu Token p/ começar a receber
    </p>
    <v-row>
      <v-col cols="5">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!tokenId"
              @click="setDialog('token')"
              color="green"
              v-bind="attrs"
              v-on="on"
              >Cadastrar Token</v-btn
            >
            <v-btn
              v-else
              @click="setDialog('token')"
              color="red"
              v-bind="attrs"
              v-on="on"
              >Alterar Token</v-btn
            >
          </template>
          <v-card
            v-if="!spinner"
            style="overflow: hidden !important; padding: 20px !important"
          >
            <v-row v-if="type == 'token'">
              <v-col cols="12">
                <v-textarea
                  v-model="tokenValue"
                  label="Valor Token Pagarme"
                ></v-textarea>
                <strong class="red--text">{{ errors.message }}</strong>
                <strong class="red--text">{{ errors.value }}</strong>
              </v-col>
              <v-col cols="12">
                <v-btn
                  v-if="!tokenId"
                  class="ml-2 mr-2"
                  color="green"
                  @click="
                    saveToken({ value: tokenValue, company_id: companyId }),
                      (dialog = false)
                  "
                >
                  Salvar Token
                </v-btn>
                <v-btn
                  v-else
                  class="ml-2 mr-2"
                  color="green"
                  @click="
                    updateValue({
                      id: tokenId,
                      value: tokenValue,
                      company_id: companyId,
                    }),
                      (dialog = false)
                  "
                >
                  Alterar Token
                </v-btn>
                <v-btn class="ml-2" color="red" @click="dialog = false">
                  Fechar
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-else
            style="overflow: hidden !important; padding: 20px !important"
          >
            <v-row>
              <v-col cols="12" align="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  watch: {
    dialog() {
      this.pvValue = null;
      this.tokenValue = null;
      this.cleanState();
    },
  },
  props: {
    companyId: Number,
  },
  data: function () {
    return {
      tokenValue: false,
      type: null,
    };
  },
  methods: {
    ...mapActions("PagarmeIntermediary", [
      "savePv",
      "savePagarmev5",
      "cleanState",
      "saveToken",
      "check",
      "updateValue",
      "setCompanyId"
    ]),

    setDialog(type) {
      this.type = type;
    },
  },
  computed: {
    ...mapState("PagarmeIntermediary", [
      "spinner",
      "errors",
      "pvId",
      "tokenId",
    ]),
    dialog: {
      
      get() {
        return this.$store.state.RedeIntrermediary.dialog;
      },
      set(value) {
        this.$store.commit("RedeIntrermediary/SET_DIALOG", value);
      },
    },
  },
  mounted() {
    this.setCompanyId(this.companyId); 

  // Exibe o valor da prop companyId no console
  console.log('Valor recebido no companyId (prop):', this.companyId);

  // Verifica o valor no Vuex após a ação
  this.$nextTick(() => {
    console.log('Valor de company_id no Vuex:', this.$store.state.PagarmeIntermediary.company_id);
  });
  
    this.check({ company_id: this.companyId });
  },
};
</script>

<style scoped>
.pagarme-pgto-card {
  border: solid grey 1px;
  box-shadow: none !important;
  padding: 20px;
}
</style>