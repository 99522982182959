<template>
  <div class="main-class">
    <h2 class="text-center py-5">Relatório p/ Catalogo Facebook</h2>

    <div class="filter-class">
      <v-row>
        <v-col
          cols="12"
          class="filter-col-class mb-3"
          style="border-bottom: solid grey 1px"
          ><h4>Filtros:</h4></v-col
        >
        <v-col v-if="adm" cols="12">
          <v-autocomplete
            @click="form.facebookURL = false"
            v-model="form.company_id"
            :items="companyList"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="4">
          <v-autocomplete
            v-model="form.gender"
            :items="genders"
            @click="form.facebookURL = false"
            item-text="name"
            item-value="value"
            label="Genero *"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="form.only_facebook_description"
            @click.stop="form.facebookURL = false"
            label="Trazer somente produtos com o campo descrição Facebook"
          ></v-switch>
        </v-col>
        <!-- <v-col class="filter-col-class" cols="12" md="2">
          <v-text-field
            type="date"
            v-model="form.init_date"
            label="Data Inicial"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col class="filter-col-class" cols="12" md="2">
          <v-text-field
            type="date"
            v-model="form.finish_date"
            label="Data Final"
          ></v-text-field>
        </v-col> -->
        <!-- {{ value }} -->
        <!-- <v-col class="filter-col-class" cols="12" md="8">
          <v-select
            v-model="form.status"
            :items="items"
            attach
            chips
            label="Status"
            multiple
            dense
            class="mt-2"
            item-text="name"
            item-value="value"
          ></v-select
        ></v-col> -->
        <v-col class="filter-col-class" cols="12" md="6" align="left">
          <v-btn
            color="green darken-2 white--text mb-3"
            dark
            @click.stop="(form.facebookURL = false), (form.facebookURL = true)"
          >
            Gerar URL Catálogo Facebook
          </v-btn>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.name !== 'xs'"
          cols="12"
          md="6"
          class="filter-col-class"
          align="right"
        >
          <v-btn
            @click="showReport(form)"
            color="green darken-2 white--text mb-3"
            >Filtrar</v-btn
          >
        </v-col>

        <v-col v-else cols="12" md="6" class="filter-col-class" align="left">
          <v-btn
            @click="showReport(form)"
            color="green darken-2 white--text mb-3"
            >Filtrar</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <modal-vue :dialog="form.facebookURL" />
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import modalVue from "../../../../Integration/FacebookCatalog/modal.vue";

export default {
  methods: {
    ...mapActions("FacebookCatalogReport", ["showReport"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
  },
  computed: {
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  components: { modalVue },
  data: function () {
    return {
      adm: false,
      form: {
        init_date: null,
        finish_date: null,
        status: null,
        only_facebook_description: false,
        facebookURL: false,
      },
      items: [
        { value: 0, name: "Pendente" },
        { value: 1, name: "Pgto em Analise" },
        { value: 2, name: "Separação" },
        { value: 3, name: "Transito" },
        { value: 4, name: "Entregue" },
        { value: 5, name: "Expirado" },
        { value: 6, name: "Cancelado" },
        { value: 7, name: "Pgto Recusado" },
      ],
      genders: [
        { value: null, name: "Nenhum" },
        { value: "f", name: "Feminino" },
        { value: "u", name: "Unisex" },
        { value: "m", name: "Masculino" },
      ],
    };
  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
  },
};
</script>

<style scoped>
.main-class {
  background: white !important;
  height: 100% !important;
}

.filter-class {
  border-bottom: solid grey 1px;

  padding: 5px 30px 0 30px;
}
.filter-col-class {
  padding-top: 2px !important;
  padding-bottom: 10px !important;
}
</style>