<template>
  <v-card class="pagarme-pgto-card">
    <h3>Chave Pagarme</h3>

    <!-- Exibe a resposta apenas se errorMessage estiver presente -->
    <v-card v-if="errorMessage">
      <h3>Erro: A validade do token de 3 minutos</h3>
      <v-btn @click="setDataIntegrationPagarme()"> Tentar Novamente</v-btn>
    </v-card>

    <!-- Exibe mensagem de sucesso se não houver erro e houver responseData -->
    <v-card v-if="$route.query.authorization_code && !errorMessage">
      <h3>Chave Retornada:: {{ $route.query.authorization_code }}</h3>
      <v-btn @click="SavePagarV5()">Finalizar Minha Integração</v-btn>
    </v-card>

    <v-btn v-if="!errorMessage && !$route.query.authorization_code" id="pagarme-hub" @click="setDataIntegrationPagarme()">
      Integrar com Pagarme
    </v-btn>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { publicAppKey, myUrl, environment, token } from "@/global";

export default {
  data() {
    return {
      tokenValue: false,
      type: null,
      config: Object,
      installId: null,
      errorMessage: null,  // Adicionada para armazenar a mensagem de erro
    };
  },
  methods: {
    ...mapActions("PagarmeIntermediary", [
      "savePv",
      "savePagarmev5",
      "cleanState",
      "saveToken",
      "check",
      "updateValue",
      "sendDataToHub",
    ]),

    setDialog(type) {
      this.type = type;
    },

    SavePagarV5(){
      // Configurando os dados para a requisição
      this.config = {
        code: this.$route.query.authorization_code,
        token: token
      };

      // Chama a ação e captura o resultado
      this.savePagarmev5(this.config).then(res => {
          // Verifica se a resposta contém a chave 'error'
          if (res.data.error) {
            // Defina a variável de erro com base no conteúdo da resposta
            this.errorMessage = res.data.error;
            console.error('Erro retornado:', this.errorMessage);
          } else {
            // Caso contrário, continue com o fluxo normal (processamento bem-sucedido)
            console.log('Resposta bem-sucedida:', res.data);
            return res.data;
          }
        })
        .catch(error => {
          // Trata qualquer erro que ocorra na requisição
          console.error('Erro na requisição:', error);
          this.errorMessage = 'Ocorreu um erro na requisição.';
        });
    },

    setDataIntegrationPagarme() {
      // hub config
      this.config = {
        publicAppKey: publicAppKey,
        installId: this.getInstallId(),
        redirectUrl: `${myUrl}/form-receive-data/${this.$route.params.id}`,
        language: "pt-BR",
        environment: environment,
      };

      this.sendDataToHub(this.config);
    },

    getInstallId() {
      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("installId")) {
        this.installId = searchParams.get("installId");
      }
      return this.installId;
    },
  },

  computed: {
    ...mapState("ReceiveDataRule", ["formData"]),
    ...mapState("PagarmeIntermediary", [
      "spinner",
      "errors",
      "pvId",
      "tokenId",
    ]),
    dialog: {
      get() {
        return this.$store.state.RedeIntrermediary.dialog;
      },
      set(value) {
        this.$store.commit("RedeIntrermediary/SET_DIALOG", value);
      },
    },
  },
  mounted() {
    this.check({ company_id: this.companyId });
  },
};
</script>

<style scoped>
.pagarme-pgto-card {
  border: solid grey 1px;
  box-shadow: none !important;
  padding: 20px;
}
</style>
