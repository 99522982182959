<template>
  <span>
    <!-- {{ base64 }} -->
    <v-row style="position: relative; padding:10px">
      <a class="black--text" @click="setAllGridModal(false)">
      
        <span style="position: absolute; top: 0px; right: 20px; font-size:30px" class="mdi mdi-close-thick"></span>
      </a>

      <v-col cols="12" sm="3" xl="3">
        <v-text-field
          hide-details
          v-model="colorName"
          v-if="writeColorName"
          label="Nome Cor *"
        >
        </v-text-field>
        <v-autocomplete
          v-else
          hide-details
          v-model="colorName"
          :items="colors"
          item-text="name"
          item-value="name"
          label="Nome Cor *"
          color="amber darken-4"
        ></v-autocomplete>
        <a
          v-if="!writeColorName"
          @click="setWriteColorName(true)"
          style="
            font-size: 12px;
            padding-top: -20px !important;
            margin-top: -20px !important;
          "
        >
          Digitar nome cor
        </a>
        <a
          v-else
          @click="setWriteColorName(false)"
          style="
            font-size: 12px;
            padding: 0px !important;
            margin: 0px !important;
          "
        >
          Voltar Selecao Cores
        </a>
        <div v-if="colorName !== 'N/A' && !useImg">Cor</div>
        <v-color-picker
          v-if="colorName !== 'N/A' && !useImg"
          v-model="colorCode"
          mode="hexa"
        ></v-color-picker>
        <div v-if="secondColor">Cor Secundaria</div>
        <v-color-picker
          v-if="secondColor"
          mode="hexa"
          v-model="SecondCodeColor"
        ></v-color-picker>
        <v-switch
          v-if="colorName !== 'N/A' && !useImg"
          class="no-padding"
          color="amber darken-4"
          v-model="secondColor"
          label="Cor Secundaria"
        ></v-switch>
        <br />
        <v-img
          v-if="useImg"
          contain
          :src="base64"
          height="250px"
          width="65px"
        ></v-img>
        <strong>
          <label v-if="useImg" for="imgpicker">Selecione uma Imagem</label>
        </strong>
        <image-uploader
          id="imgpicker"
          v-if="useImg"
          class="mb-4"
          :maxWidth="800"
          :maxHeight="1000"
          :quality="1.0"
          outputFormat="verbose"
          @input="setImage"
          :preview="false"
          :capture="false"
          accept="video/*, image/*"
        ></image-uploader>
        <v-switch
          class="no-padding"
          color="amber darken-4"
          v-model="useImg"
          label="Utilizar Imagem de Fundo"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="1" xl="1"></v-col>
      <v-col cols="12" sm="7" xl="7">
        <br>
        <span v-for="(item, index) in values" :key="index">
          <v-row style="background:#EFEBE9; border-radius:10px">
            
            <v-col  cols="5">
              <v-text-field
                :disabled="toEnable()"
                type="number"
                label="Qtd"
                v-model="item.balance"
              ></v-text-field>
              <!-- <span v-show="false">{{imte.color = colorName}}</span> -->
              <!-- <v-text-field label="Cor" :value="colorName" v-model="item.color"></v-text-field> -->
            </v-col>
            
            <v-col  cols="5">
              <v-text-field
                :disabled="toEnable()"
                hide-details
                v-model="item.size"
                v-if="writeSizeName"
                label="Nome Tamanho *"
              >
              </v-text-field>
              <v-autocomplete
                v-else
                :disabled="toEnable()"
                v-model="item.size"
                :items="sizes"
                item-text="name"
                item-value="name"
                label="Tamanho *"
                hide-details
                color="amber darken-4"
              ></v-autocomplete>
              <a
                :disabled="toEnable()"
                v-if="!writeSizeName"
                @click="setWriteSizeName(true)"
                style="
                  font-size: 12px;
                  padding: 0px !important;
                  margin-top: 0px !important;
                  margin-bottom: 20px !important;
                "
              >
                Digitar nome tamanho
              </a>

              <a
                v-else
                :disabled="toEnable()"
                @click="setWriteSizeName(false)"
                style="
                  font-size: 12px;
                  padding: 0px !important;
                  margin: 0px !important;
                "
              >
                Voltar Selecao Tamanho
              </a>
            </v-col>
            <v-col cols="1">
              <span>
                <button :disabled="toEnable()" @click="removeItemFromArray(index)" class="pa-sm-2 pa-xs-0 ml-auto">
                  <span style="font-size:30px" class="mdi mdi-trash-can"></span>
                </button>
              </span>
            </v-col>
            <v-col cols="5">
              <v-text-field
                :disabled="toEnable()"
                type="text"
                label="Codigo"
                v-model="item.code"
              ></v-text-field>
            </v-col>

            <v-col cols="5" >
              <v-text-field
                :disabled="toEnable()"
                type="text"
                label="Id Integrador"
                v-model="item.id_grid_integrator"
              ></v-text-field>
            </v-col>
            
          </v-row>
          <p style="color:white">.</p>
        </span>
        <h3 v-if="toEnable()" class="ma-3">
          <strong class="red--text">* Selecione uma Cor</strong>
          <strong class="red--text">* {{ allGridModalErrors }}</strong>
        </h3>
               <v-btn
          class="mb-15"
          :disabled="toEnable()"
          color="orange"
          @click="addNewGrid()"
          >Adicionar Grade</v-btn
        >
        <v-btn
        color="green"
        :disabled="toEnable()"
        class="white--text mb-xs-50"
        style="position: absolute; bottom: 20px; right: 20px;"
        @click="saveAll()"
      >
        Cadastrar
      </v-btn>
      
        
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      base64: null,
      useImg: false,
      teste: "ffdfff",
      colorName: null,
      sizeName: null,
      colorCode: null,
      secondColor: false,
      SecondCodeColor: null,
      writeColorName: false,
      writeSizeName: false,
      values: [
        {
          balance: 1,
          size: null,
          color: null,
          code_color: null,
          second_code_color: null,
        },
      ],
    };
  },
  computed: {
    ...mapState("Grid", [
      "modal",
      "list",
      "allGridModal",
      "allGridModalErrors",
    ]),
    ...mapState("Utils", ["colors", "sizes"]),
  },
  methods: {
    ...mapActions("Grid", [
      "setModal",
      "setList",
      "setAllGridModal",
      "saveMany",
    ]),
    setImage: function (output) {
      // console.log(output);
      // this.hasImage = true;
      this.base64 = output.dataUrl;
      // this.formData.images.push({ base64: base64 });
      // this.setSpinnerLoadImage(false);
    },
    setWriteColorName(value) {
      this.writeColorName = value;
    },

    setWriteSizeName(value) {
      this.writeSizeName = value;
    },

    removeItemFromArray(value) {
      this.values.splice(value, 1);
    },
    setColors() {},
    toEnable() {
      if (this.colorName && this.colorCode) {
        return false;
      } else {
        return true;
      }
    },
    saveAll() {
      for (let i = 0; i < this.values.length; i++) {
        this.values[i].color = this.colorName;
        if (this.base64) {
          this.values[i].bg_color_img = this.base64;
          this.values[i].code_color = null;
          this.values[i].second_code_color = null;
        } else {
          this.values[i].code_color = this.colorCode.hex;
          if (this.secondColor) {
            this.values[i].second_code_color = this.SecondCodeColor.hex;
          } else {
            this.values[i].second_code_color = null;
          }
        }
        this.values[i].product_id = this.$route.params.id;
        this.values[i].reason = "b";
        // more statements
      }
      let toSend = {
        product_id: this.$route.params.id,
        data: {
          ...this.values,
        },
      };
      this.saveMany(toSend);
    },
    addNewGrid() {
      this.values = [
        ...this.values,
        {
          balance: 1,
          size: null,
          color: this.colorName,
          code_color: this.colorCode.hex,
        },
      ];
    },
    setAllGrid() {
      this.setAllGridModal(!this.allGridModal);
    },
  },
  mounted() {
    // this.setList(this.$route.params.id);
    // toastAlert("success");
  },
};
</script>

<style>


</style>