<template>
  <span>
    <v-list dense>
      <span v-for="menu of menus" :key="menu.title">
        <router-link
          style="text-decoration: none"
          :to="menu.route"
          v-if="menuRule(menu.admin)"
        >
          <v-list-item @click="menu.show = !menu.show">
            <v-list-item-action>
              <v-icon class="left-menu-icons">{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-left-list">
                {{ menu.name }}
                <v-icon
                  v-if="menu.children"
                  class="icon-active"
                  color="#ffbf14"
                  >{{ chevronChange(menu.show) }}</v-icon
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span v-if="menu.show">
            <v-list>
              <div v-for="submenu of menu.children" :key="submenu.name">
                <v-list-item
                  :to="submenu.route"
                  text
                  color="#000"
                  v-if="menuRule(submenu.admin)"
                >
                  <v-icon v-if="submenu.icon" class="left-submenu-icons">{{
                    submenu.icon
                  }}</v-icon>
                  <v-list-item-title class="submenu-left-text">{{
                    submenu.name
                  }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </span>
        </router-link>
      </span>
    </v-list>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Auth", ["user"]),
  },
  menuRule(admin) {
    if (!admin) {
      return true;
    }
    if (admin == this.user.admin) {
      return true;
    } else {
      return false;
    }
  },
  data: () => ({
    menus: [
      {
        route: "/",
        name: "Home",
        icon: "fas fa-home",
        admin: false,
      },
      {
        route: "/users-store",
        name: "Usuarios",
        icon: "fas fa-user-circle",
        admin: true,
      },
      {
        route: "/company",
        name: "Lojas",
        icon: "fas fa-store-alt",
        admin: true,
      },
      {
        route: "",
        name: "Produtos",
        icon: "fas fa-tshirt",
        show: false,
        children: [
          {
            route: "/definition",
            name: "Definições",
            icon: "far fa-dot-circle",
            admin: true,
          },
          // {
          //   route: "/size",
          //   name: "Tamanhos",
          //   icon: "far fa-dot-circle",
          //   admin: true,
          // },
          {
            route: "/category",
            name: "Categorias",
            icon: "far fa-dot-circle",
            admin: true,
          },
          {
            route: "/sub-category",
            icon: "far fa-dot-circle",
            name: "Sub Categorias",
            admin: true,
          },
          {
            route: "/brand",
            icon: "far fa-dot-circle",
            name: "Marcas",
          },
          {
            route: "/atribute-group",
            icon: "far fa-dot-circle",
            name: "Grupo Atributos",
          },
          {
            route: "/atribute",
            icon: "far fa-dot-circle",
            name: "Atributos",
          },
          {
            route: "/product-group",
            icon: "far fa-dot-circle",
            name: "Grupo Produtos",
            admin: true,
          },
          {
            route: "/product",
            icon: "far fa-dot-circle",
            name: "Produtos",
          },
          {
            route: "/variation-group",
            icon: "far fa-dot-circle",
            name: "Grupo de Variações",
          },

          {
            route: "/exact-search",
            icon: "far fa-dot-circle",
            name: "Busca Exata de Produtos",
          },
        ],
      },
      {
        route: "",
        admin: false,
        name: "Consumidor",
        icon: "fas fa-users",
        show: false,
        children: [
          {
            route: "/users-consumer",
            name: "Usuarios",
            icon: "far fa-dot-circle",
            admin: false,
          },
          {
            route: "/contact-list",
            name: "Lista de Contatos",
            icon: "far fa-dot-circle",
            admin: false,
          },
        ],
      },
      {
        route: "",
        name: "Pedidos",
        icon: "fas fa-credit-card",
        show: false,
        children: [
          {
            route: "/pendingBuys",
            icon: "far fa-dot-circle",
            name: "A Serem Aprovados",
          },
          {
            route: "/sortingOutBuys",
            icon: "far fa-dot-circle",
            name: "Em Separaçao",
          },
          {
            route: "/inTransitBuys",
            icon: "far fa-dot-circle",
            name: "Enviados",
          },
          {
            route: "/finished",
            icon: "far fa-dot-circle",
            name: "Entregues",
          },
          {
            route: "/not-finished-buys",
            icon: "far fa-dot-circle",
            name: "Não Finalizados",
          },
        ],
      },
      {
        route: "",
        admin: false,
        name: "Cupons",
        icon: "fas fa-percent",
        show: false,

        children: [
          {
            route: "/coupon",
            name: "Gerir / Cadastrar",
            icon: "far fa-dot-circle",
            admin: false,
          },
        ],
      },
      {
        route: "",
        name: "Configuraçoes",
        icon: "fas fa-cog",
        show: false,
        children: [
          {
            route: "/receive-data",
            icon: "far fa-dot-circle",
            name: "Recebimento",
          },
          {
            route: "/delivery-settings",
            icon: "far fa-dot-circle",
            name: "Configurações Envio",
          },

          {
            route: "/integrations/stock",
            icon: "far fa-dot-circle",
            name: "Integrações",
          },
        ],
      },
      {
        route: "",
        name: "Relatórios",
        icon: "fas fa-chart-line",
        show: false,
        children: [
          {
            route: "/reports/orders",
            name: "Pedidos",
            icon: "far fa-dot-circle",
          },
          {
            route: "/reports/facebook-catalog",
            name: "Catalogo Facebook",
            icon: "far fa-dot-circle",
          },
        ],
      },

      {
        route: "",
        name: "E-commerce",
        icon: "fa-solid fa-store",
        show: false,
        children: [
          {
            route: "/comments",
            name: "Gerenciar comentários",
            icon: "far fa-dot-circle",
          },
        ],
      },

      {
        route: "/layout-relevance",
        name: "Layout",
        icon: "fa-solid fa-table",
        show: false,
      },
    ],
    drawer: null,
  }),
  methods: {
    menuRule(admin) {
      if (!admin) {
        return true;
      }
      if (admin == this.user.admin) {
        return true;
      } else {
        return false;
      }
    },
    chevronChange(value) {
      if (value == false) {
        return "mdi-chevron-right";
      } else {
        return "mdi-chevron-down";
      }
    },
  },
};
</script>

<style>
.left-submenu-icons {
  font-size: 10px !important;
  color: white !important;
  margin-left: 25px;
}

.left-menu-icons {
  font-size: 20px !important;
  color: white !important;
  text-decoration: none !important;
  z-index: 100;
  margin-right: 0px !important;
  padding: 0px !important;
  opacity: 1 !important;
  /* font-weight: normal !important; */
}

.text-left-list {
  /* font-weight: bold !important; */
  color: white;
  opacity: 1;
  font-size: 17px !important;
}

.submenu-left-text {
  color: white;
  padding-left: 17px;
}

@media only screen and (max-width: 600px) {
  .text-left-list {
    font-size: 12px !important;
  }
  .left-menu-icons {
    font-size: 18px !important;
  }
}
</style>